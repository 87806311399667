import styled, { css } from 'styled-components';
import checkmark from '../../../images/checkmark-revert.svg';
import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { font } from '../../../styles/atoms/typography';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  text-align: ${props => (props.hasReference ? 'left' : 'center')};
  align-items: ${props => (props.hasReference ? 'flex-start' : 'center')};
  justify-content: ${props => (props.hasReference ? 'flex-start' : 'center')};
  gap: 24px;
  flex: 1 0 45%;
  z-index: 1;

  ${atMinWidth.lg`
    max-width: ${props => (props.hasReference ? '50%' : '')};
  `}

  ${props =>
    props.increasedTopPadding &&
    css`
      ${atMinWidth.lg`
        padding-top: 90px
      `}
    `}

  ${props =>
    props.isFullWidth &&
    css`
      padding-bottom: 32px;

      ${atMinWidth.lg`
        padding-bottom: 0;
      `}
    `}

    .image-for-video {
      margin-top: 10px;
      cursor: pointer;
    }

  .badges {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    margin-top: 16px;

    ${atMinWidth.lg`
      gap: 0px;
    `}

    ${atMinWidth.xl`
      width: fit-content;
      gap: 32px;
    `}

    .hero-badge {
      width: 100%;
      height: 100%;
      max-height: 100px;
      object-fit: contain;
    }
  }
`;

export const Kicker = styled.p`
  ${font('overline', 'md', '700')}
  color: ${props => props.color};
`;

export const HeadingStyles = styled.div`
  * {
      ${font('display', 'lg', '700')}
      color: ${props => props.color};

      ${atMinWidth.sm`
        font-size: 2.85rem;
        line-height: 1.1;
      `}
    }
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 24px;
  ${font('text', 'md', '400')}
  justify-content: inherit;

  > :empty {
    display: none;
  }

  .hero-paragraph {
    ${font('text', 'lg', '400')}
    color: ${props => props.copyColor};

    ${atMinWidth.md`
      ${font('text', 'xl', '400')}
    `}

    &:has(.cta-btn-section) {
      display: flex;
      flex-flow: row wrap;
      justify-content: inherit;
      gap: 10px;
    }

    subscript,
    superscript {
      font-size: 16px;
      line-height: 1;
    }

    subscript {
      vertical-align: sub;
    }

    superscript {
      vertical-align: super;
    }

    a {
      &:has(subscript),
      &:has(superscript) {
        color: inherit;
        font-weight: 400;
        text-decoration: none;

        &:hover {
          opacity: 0.7;

          subscript,
          superscript {
            border-bottom: none;
          }
        }
      }

      subscript,
      superscript {
        border-bottom: 1px solid currentColor;
      }
    }
  }

  ul {
    color: ${props => props.copyAccentColor};
    margin-bottom: 0;

    p {
      margin-bottom: 4px;
    }

    li {
      position: relative;
      ${font('text', 'xl', '700')}
      color: inherit;
      list-style-type: none;
      margin-bottom: 16px;
      padding-left: 38px;

      &:after {
        content: ' ';
        position: absolute;
        display: block;
        width: 22px;
        height: 22px;
        background: url(${checkmark});
        left: 0;
        top: 6px;
      }

      ul {
        li {
          ${font('text', 'xl', '400')}
          color: ${props => props.copyAccentColor};
          padding-left: 0px;

          &:after {
            display: none;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  // Wonder if this is needed.. 
  .claim-container {
    margin-bottom: 24px;
    font-weight: 400;

    .excerpt {
      padding: 16px 24px;
      border-radius: 10px;
      font-style: normal;
      font-size: 28.13px;
      line-height: 120%;
      letter-spacing: -0.3px;
      margin-bottom: 8px;
      background: #1c3eff;
    }

    .disclaimer {
      font-style: italic;
      font-size: 16.2px;
      line-height: 110%;
      letter-spacing: -0.16px;
    }
  }

  .structured-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      width: 22px;
      margin-right: 16px;
      vertical-align: middle;
    }

    p.list-label {
      margin-bottom: 0;
      color: ${props => props.copyAccentColor};
    }
  }

  blockquote {
    p {
      margin-bottom: 4px;
      color: ${props => props.copyAccentColor};
    }

    footer {
      ${font('text', 'md', '700')};
      color: ${props => props.copyAccentColor};

      ${atMinWidth.md`
        ${font('text', 'xl', '700')};
      `}
    }
  }
`;

export const MobileAppDisclaimer = styled.span`
  margin-top: -16px;
  font-size: 12px;
  color: ${props => props.color};
`;

export const Copyright = styled.div`
  ${font('text', 'sm', '400')}
  text-align: center;
  width: 100%;
  margin-top: 32px;
  color: inherit;

  a {
    text-decoration: none;
    appearance: none;

    span {
      color: inherit;
    }
  }
`;
